.cursorPointer{
    cursor: pointer;
}

.tableContainer{
    

    .tableTools{
        .tableTool{
            width: 100%;
        }
    }
    
    .tableGrid{
        th, td{
            border: 1px solid;
        }
    }

    .tableComponent{
        // display: block;
        overflow-x: scroll;
        white-space: nowrap;

        border-spacing: 0;
        text-align: left;

        .tableHeader{
            font-size: 24px;
            background-color: var(--theme-color);
            color: white;
            tr{ 
                th{ 
                    column-gap: 0;
                    font-weight: 400;
                    padding: 10px 5px;
                    font-size: 14px;
                } 
            }
        }
    
        .tableBody{
            // line-height: 60px;
    
            .tableRow{
                font-size: 18px;
                margin: 10px 0;

                td{
                    font-size: 14px;
                    padding: 20px 5px;
                }

                &:hover{
                    background-color: var(--theme-color-lighter);
                }

            }
        }
    }
}

@media (max-width: 600px) {
    .tableContainer{        
        .tableComponent{
            max-width: 85vw;
        }
    }
}