.reportsContainer{
    height: inherit;

    .reportsFilter{
        align-items: center;
        flex-wrap: wrap;
    
        .reportsFilterField{
            min-width: 300px;
            margin: 0 10px 0 0;
        }
    }    
}
