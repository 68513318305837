.dtPickerContainer{
    margin: 10px 0;

    .dtPCInput{
        margin: 5px 0;
        height: 40px;
        border: none;
        border-radius: 0.3rem;
        padding: 0 10px;
        width: 100%;
        font: inherit;

        &:focus{
            border: none;
            outline: none;
        }
    }
    
    .readOnly{
        background-color: #fbfbfb;
        color: #9d9d9d;
        cursor: not-allowed;
        caret-color: black;
        height: 40px;
    }
}