.actionButton {
    width: inherit;
    height: inherit;
    min-height: 50px;

    border-radius: 0.3rem;
    background-color: none;
    outline: none;

    // &:hover {
    //     cursor: pointer;
    // }
}

.primaryButton {
    background-color: var(--theme-color);
    color: var(--theme-secondary);
    border: 1px solid var(--theme-secondary);
}

.secondaryButton {
    background-color: var(--theme-secondary);
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
}

.dangerButton {
    background-color: #FF3131;
    color: var(--theme-secondary);
    border: 1px solid var(--theme-color);
}

.disabledButton{
    background-color: grey;
    color: var(--theme-secondary);
}